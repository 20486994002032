<template>
  <div class="patient-information-form-wrapper">
    <div v-if="orderInfoPreview" class="form">
      <div v-if="props.isEditable || orderInfoPreview.patientInfo" class="patient-information-part">
        <span class="header-with-select-box">
          <h5>Patient Information</h5>
          <select v-if="props.isEditable" id="patient-select" v-model="patientSelection" class="custom-select-arrow">
            <option disabled value="null">Select Patient From Address Book</option>
            <option
              v-for="patient in props.patientAddressBook.filter(
                (patient) => patient.firstName && patient.lastName && patient.dob && patient.insuranceId
              )"
              :key="patient.id">
              {{ patient.firstName }} {{ patient.lastName }} ({{ patient.dob }}), {{ patient.insuranceId }}
            </option>
          </select>
        </span>
        <p>Patient Name</p>
        <hr class="bold-hr" />
        <div class="fields-in-row">
          <div class="input-elements-group patient-prefix">
            <label for="prefix">Prefix</label>
            <input
              id="prefix"
              v-model="orderInfoPreview.patientInfo.prefix"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'prefix', orderInfoPreview.patientInfo.prefix)" />
          </div>
          <div class="input-elements-group patient-first">
            <label for="first">First Name*</label>
            <input
              id="first"
              v-model="orderInfoPreview.patientInfo.firstName"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'firstName', orderInfoPreview.patientInfo.firstName)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('firstName') && props.formButtonClicked"
              class="input-error-notification">
              Please enter patient first name.
            </span>
          </div>
          <div class="input-elements-group patient-middle">
            <label for="middle">Middle Name</label>
            <input
              id="middle"
              v-model="orderInfoPreview.patientInfo.middleName"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'middleName', orderInfoPreview.patientInfo.middleName)" />
          </div>
          <div class="input-elements-group patient-last">
            <label for="last">Last Name*</label>
            <input
              id="last"
              v-model="orderInfoPreview.patientInfo.lastName"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'lastName', orderInfoPreview.patientInfo.lastName)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('lastName') && props.formButtonClicked"
              class="input-error-notification">
              Please enter patient last name.
            </span>
          </div>
          <div class="input-elements-group patient-suffix">
            <label for="suffix">Suffix</label>
            <input
              id="suffix"
              v-model="orderInfoPreview.patientInfo.suffix"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'suffix', orderInfoPreview.patientInfo.suffix)" />
          </div>
        </div>

        <hr class="light-hr" />
        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="insurance-provider">Insurance Provider*</label>
            <input
              id="insurance-provider"
              v-model="orderInfoPreview.insuranceProvider"
              type="text"
              placeholder=""
              readonly="true" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('insuranceProvider') && props.formButtonClicked"
              class="input-error-notification">
              Please enter patient Insurance Provider.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="insurance-member-id">Insurance Member ID*</label>
            <input
              id="insurance-member-id"
              v-model="orderInfoPreview.patientInfo.insuranceId"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'insuranceId', orderInfoPreview.patientInfo.insuranceId)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('insuranceId') && props.formButtonClicked"
              class="input-error-notification">
              Please enter patient Insurance Member ID.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="insurance-member-name">Name of the Insured*</label>
            <input
              id="insurance-member-name"
              v-model="orderInfoPreview.patientInfo.insuranceMemberName"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="
                emit('updatePatientInfo', 'insuranceMemberName', orderInfoPreview.patientInfo.insuranceMemberName)
              " />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('insuranceMemberName') && props.formButtonClicked"
              class="input-error-notification">
              Please enter name of the insured member on the card.
            </span>
          </div>

          <div class="input-elements-group">
            <label for="benefit-type">Benefit Type*</label>
            <select
              v-if="props.isEditable && !orderInfoPreview.benefitType"
              id="benefit-type"
              v-model="orderInfoPreview.benefitType"
              class="custom-select-arrow">
              <option disabled value="null">Select Benefit Type</option>
              <option v-for="benefitType in benefitTypes" :key="benefitType">{{ benefitType }}</option>
            </select>
            <input
              v-if="!props.isEditable || orderInfoPreview.benefitType"
              id="benefit-type"
              v-model="orderInfoPreview.benefitType"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'benefitType', orderInfoPreview.benefitType)" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('benefitType') && props.formButtonClicked"
              class="input-error-notification">
              Please select whether medication is covered under pharmacy or medical benefits.
            </span>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="pcn">Rx PCN*</label>
            <input
              id="pcn"
              v-model="orderInfoPreview.processorControlNumber"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="
                emit('updateOrderInfoField', 'processorControlNumber', orderInfoPreview.processorControlNumber)
              " />
            <span v-if="props.isEditable && !isPCNValid && props.formButtonClicked" class="input-error-notification">
              Please enter Rx Processor Control Number (PCN).
            </span>
          </div>
          <div class="input-elements-group">
            <label for="bin">Rx BIN Number*</label>
            <input
              id="bin"
              v-model="orderInfoPreview.bin"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'bin', orderInfoPreview.bin)" />
            <span v-if="props.isEditable && !isBinValid && props.formButtonClicked" class="input-error-notification">
              Please enter Rx BIN.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="insurance-plan-type">Plan Type</label>
            <input
              id="insurance-plan-type"
              v-model="orderInfoPreview.insurancePlanType"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'insurancePlanType', orderInfoPreview.insurancePlanType)" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('insurancePlanType') && props.formButtonClicked"
              class="input-error-notification">
              Please enter insurance plan type.
            </span>
          </div>

          <div class="input-elements-group">
            <label for="grp">Group Number (Rx GRP)</label>
            <input
              id="grp"
              v-model="orderInfoPreview.grpNumber"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'grpNumber', orderInfoPreview.grpNumber)" />
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="date-of-birth">Date of Birth*</label>
            <input
              id="date-of-birth"
              v-model="orderInfoPreview.patientInfo.dob"
              type="date"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'dob', orderInfoPreview.patientInfo.dob)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('dob') && props.formButtonClicked"
              class="input-error-notification">
              Please enter patient date of birth.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="phone">Phone*</label>
            <input
              id="phone"
              v-model="orderInfoPreview.patientInfo.phone"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'phone', orderInfoPreview.patientInfo.phone)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('phone') && props.formButtonClicked"
              class="input-error-notification">
              Please enter patient phone number.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="ssn">Social Security Number*</label>
            <input
              id="ssn"
              v-model="orderInfoPreview.patientInfo.ssn"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'ssn', orderInfoPreview.patientInfo.ssn)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('ssn') && props.formButtonClicked"
              class="input-error-notification">
              Please enter patient social security number.
            </span>
          </div>
        </div>
        <p v-if="orderInfoPreview.patientInfo.gender">Patient Gender*</p>
        <div class="patient-gender-wrapper">
          <div>
            <input
              id="male"
              v-model="orderInfoPreview.patientInfo.gender"
              type="radio"
              name="gender"
              value="Male"
              :readonly="!props.isEditable" />
            <label for="male">Male</label>
          </div>
          <div>
            <input
              id="female"
              v-model="orderInfoPreview.patientInfo.gender"
              type="radio"
              name="gender"
              value="Female"
              :readonly="!props.isEditable" />
            <label for="female">Female</label>
          </div>
          <span
            v-if="props.isEditable && !patientFieldNotEmpty('gender') && props.formButtonClicked"
            class="input-error-notification">
            Please enter patient gender.
          </span>
        </div>

        <br />

        <p>Patient Address</p>
        <hr class="bold-hr" />
        <div class="input-elements-group">
          <label for="address-line-1">Address Line 1*</label>
          <input
            id="address-line-1"
            v-model="orderInfoPreview.patientInfo.address1"
            type="text"
            placeholder=""
            :readonly="!props.isEditable"
            @change="emit('updatePatientInfo', 'address1', orderInfoPreview.patientInfo.address1)" />
          <span
            v-if="props.isEditable && !patientFieldNotEmpty('address1') && props.formButtonClicked"
            class="input-error-notification">
            Please enter patient address.
          </span>
        </div>
        <div class="input-elements-group">
          <label for="address-line-2">Address Line 2</label>
          <input
            id="address-line-2"
            v-model="orderInfoPreview.patientInfo.address2"
            type="text"
            placeholder=""
            :readonly="!props.isEditable"
            @change="emit('updatePatientInfo', 'address2', orderInfoPreview.patientInfo.address2)" />
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="city">City*</label>
            <input
              id="city"
              v-model="orderInfoPreview.patientInfo.city"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'city', orderInfoPreview.patientInfo.city)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('city') && props.formButtonClicked"
              class="input-error-notification">
              Please enter patient city.
            </span>
          </div>

          <div class="input-elements-group">
            <label for="insurance-state">Patient Insurance State*</label>
            <select
              v-if="props.isEditable && !orderInfoPreview.patientInfo.state"
              id="insurance-state"
              v-model="orderInfoPreview.patientInfo.state"
              class="custom-select-arrow"
              @change="emit('updatePatientInfo', 'state', orderInfoPreview.patientInfo.state)">
              <option disabled value="null">Select State</option>
              <option v-for="state in states" :key="state">{{ state }}</option>
            </select>
            <input
              v-if="!props.isEditable || orderInfoPreview.patientInfo.state"
              id="insurance-state"
              v-model="orderInfoPreview.patientInfo.state"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'state', orderInfoPreview.patientInfo.state)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('state') && props.formButtonClicked"
              class="input-error-notification">
              Please choose patient insurance state.
            </span>
          </div>

          <div class="input-elements-group">
            <label for="zip">ZIP*</label>
            <input
              id="zip"
              v-model="orderInfoPreview.patientInfo.zip"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'zip', orderInfoPreview.patientInfo.zip)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('zip') && props.formButtonClicked"
              class="input-error-notification">
              Please choose patient zip.
            </span>
          </div>
        </div>
      </div>

      <div v-if="props.isEditable || orderInfoPreview.providerInfo" class="patient-information-part">
        <span class="header-with-select-box">
          <h5>Requesting Provider or Prescriber</h5>
          <select v-if="props.isEditable" id="provider-select" v-model="providerSelection" class="custom-select-arrow">
            <option disabled value="null">Select Provider</option>
            <option
              v-for="provider in props.providerAddressBook.filter(
                (provider) => provider.providerFirstName && provider.providerLastName && provider.npiNumber
              )"
              :key="provider.id">
              {{ provider.providerFirstName }} {{ provider.providerLastName }}, {{ provider.npiNumber }}
            </option>
          </select>
        </span>
        <hr class="bold-hr" />
        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="provider-first">First Name*</label>
            <input
              id="provider-first"
              v-model="orderInfoPreview.providerInfo.providerFirstName"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="
                emit('updateProviderInfo', 'providerFirstName', orderInfoPreview.providerInfo.providerFirstName)
              " />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('providerFirstName') && props.formButtonClicked"
              class="input-error-notification">
              Please enter provider's first name.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="provider-last">Last Name*</label>
            <input
              id="provider-last"
              v-model="orderInfoPreview.providerInfo.providerLastName"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="
                emit('updateProviderInfo', 'providerLastName', orderInfoPreview.providerInfo.providerLastName)
              " />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('providerLastName') && props.formButtonClicked"
              class="input-error-notification">
              Please enter provider's last name.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="provider-title">Provider Title*</label>
            <select
              v-if="props.isEditable && !orderInfoPreview.providerInfo.providerTitle"
              id="provider-title"
              v-model="orderInfoPreview.providerInfo.providerTitle"
              class="custom-select-arrow">
              <option disabled value="null">Select Title</option>
              <option v-for="title in providerTitleOptions" :key="title">{{ title }}</option>
            </select>
            <input
              v-if="!props.isEditable || orderInfoPreview.providerInfo.providerTitle"
              id="provider-title"
              v-model="orderInfoPreview.providerInfo.providerTitle"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'providerTitle', orderInfoPreview.providerInfo.providerTitle)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('providerTitle') && props.formButtonClicked"
              class="input-error-notification">
              Please choose provider's title.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="provider-email">Email*</label>
            <input
              id="provider-email"
              v-model="orderInfoPreview.providerInfo.email"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'email', orderInfoPreview.providerInfo.email)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('email') && props.formButtonClicked"
              class="input-error-notification">
              Please enter last name.
            </span>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="contact-name">Contact Name*</label>
            <input
              id="contact-name"
              v-model="orderInfoPreview.providerInfo.contactName"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'contactName', orderInfoPreview.providerInfo.contactName)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('contactName') && props.formButtonClicked"
              class="input-error-notification">
              Please enter contact name.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="contact-phone">Contact Phone*</label>
            <input
              id="contact-phone"
              v-model="orderInfoPreview.providerInfo.contactPhone"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'contactPhone', orderInfoPreview.providerInfo.contactPhone)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('contactPhone') && props.formButtonClicked"
              class="input-error-notification">
              Please enter contact phone number.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="contact-fax">Contact Fax*</label>
            <input
              id="contact-fax"
              v-model="orderInfoPreview.providerInfo.contactFax"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'contactFax', orderInfoPreview.providerInfo.contactFax)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('contactFax') && props.formButtonClicked"
              class="input-error-notification">
              Please enter contact fax.
            </span>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="npi">NPI Number*</label>
            <input
              id="npi"
              v-model="orderInfoPreview.providerInfo.npiNumber"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'npiNumber', orderInfoPreview.providerInfo.npiNumber)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('npiNumber') && props.formButtonClicked"
              class="input-error-notification">
              Please enter NPI Number.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="tin">TIN*</label>
            <input
              id="tin"
              v-model="orderInfoPreview.providerInfo.tin"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'tin', orderInfoPreview.providerInfo.tin)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('tin') && props.formButtonClicked"
              class="input-error-notification">
              Please enter TIN.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="specialty">Specialty*</label>
            <input
              id="specialty"
              v-model="orderInfoPreview.providerInfo.specialty"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'specialty', orderInfoPreview.providerInfo.specialty)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('specialty') && props.formButtonClicked"
              class="input-error-notification">
              Please enter prescribing provider's specialty.
            </span>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="dea">Drug Enforcement Administration Number</label>
            <input
              id="dea"
              v-model="orderInfoPreview.providerInfo.deaNumber"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'deaNumber', orderInfoPreview.providerInfo.deaNumber)" />
          </div>
          <div class="input-elements-group">
            <label for="license-number">License Number*</label>
            <input
              id="license-number"
              v-model="orderInfoPreview.providerInfo.licenseNumber"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'licenseNumber', orderInfoPreview.providerInfo.licenseNumber)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('licenseNumber') && props.formButtonClicked"
              class="input-error-notification">
              Please enter license number.
            </span>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="office-name">Practice Title*</label>
            <input
              id="office-name"
              v-model="orderInfoPreview.providerInfo.officeName"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'officeName', orderInfoPreview.providerInfo.officeName)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('officeName') && props.formButtonClicked"
              class="input-error-notification">
              Please enter practice title.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="office-phone">Office Phone*</label>
            <input
              id="office-phone"
              v-model="orderInfoPreview.providerInfo.officePhone"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'officePhone', orderInfoPreview.providerInfo.officePhone)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('officePhone') && props.formButtonClicked"
              class="input-error-notification">
              Please enter phone number.
            </span>
          </div>
          <div class="input-elements-group">
            <label for="fax">Fax*</label>
            <input
              id="fax"
              v-model="orderInfoPreview.providerInfo.fax"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'fax', orderInfoPreview.providerInfo.fax)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('fax') && props.formButtonClicked"
              class="input-error-notification">
              Please enter fax.
            </span>
          </div>
        </div>

        <p>Requesting Provider or Prescriber Address</p>
        <hr class="bold-hr" />

        <div class="input-elements-group">
          <label for="provider-address-line-1">Address Line 1*</label>
          <input
            id="provider-address-line-1"
            v-model="orderInfoPreview.providerInfo.providerAddress1"
            type="text"
            placeholder=""
            :readonly="!props.isEditable"
            @change="emit('updateProviderInfo', 'providerAddress1', orderInfoPreview.providerInfo.providerAddress1)" />
          <span
            v-if="props.isEditable && !providerFieldNotEmpty('providerAddress1') && props.formButtonClicked"
            class="input-error-notification">
            Please enter address.
          </span>
        </div>
        <div class="input-elements-group">
          <label for="provider-address-line-2">Address Line 2</label>
          <input
            id="provider-address-line-2"
            v-model="orderInfoPreview.providerInfo.providerAddress2"
            type="text"
            placeholder=""
            :readonly="!props.isEditable"
            @change="emit('updateProviderInfo', 'providerAddress2', orderInfoPreview.providerInfo.providerAddress2)" />
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group">
            <label for="provider-city">City*</label>
            <input
              id="provider-city"
              v-model="orderInfoPreview.providerInfo.providerCity"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'providerCity', orderInfoPreview.providerInfo.providerCity)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('providerCity') && props.formButtonClicked"
              class="input-error-notification">
              Please enter city.
            </span>
          </div>

          <div class="input-elements-group">
            <label for="provider-insurance-state">Provider State*</label>
            <select
              v-if="props.isEditable && !orderInfoPreview.providerInfo.providerState"
              id="provider-insurance-state"
              v-model="orderInfoPreview.providerInfo.providerState"
              class="custom-select-arrow"
              @change="emit('updatePatientInfo', 'state', orderInfoPreview.patientInfo.state)">
              <option disabled value="null">Select State</option>
              <option v-for="state in states" :key="state">{{ state }}</option>
            </select>
            <input
              v-if="!props.isEditable || orderInfoPreview.providerInfo.providerState"
              id="provider-insurance-state"
              v-model="orderInfoPreview.providerInfo.providerState"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'providerState', orderInfoPreview.providerInfo.providerState)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('providerState') && props.formButtonClicked"
              class="input-error-notification">
              Please choose state.
            </span>
          </div>

          <div class="input-elements-group">
            <label for="provider-zip">ZIP*</label>
            <input
              id="provider-zip"
              v-model="orderInfoPreview.providerInfo.providerZip"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateProviderInfo', 'providerZip', orderInfoPreview.providerInfo.providerZip)" />
            <span
              v-if="props.isEditable && !providerFieldNotEmpty('providerZip') && props.formButtonClicked"
              class="input-error-notification">
              Please enter ZIP.
            </span>
          </div>
        </div>
      </div>

      <div class="patient-information-part">
        <h5>Medical Information</h5>
        <hr class="bold-hr" />
        <div class="fields-in-row">
          <div class="input-elements-group medication">
            <label for="medication">Medication*</label>
            <input
              id="medication"
              v-model="orderInfoPreview.medication"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'medication', orderInfoPreview.medication)" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('medication') && props.formButtonClicked"
              class="input-error-notification">
              Please enter medication.
            </span>
          </div>
          <div class="input-elements-group strength">
            <label for="strength">Strength*</label>
            <input
              id="strength"
              v-model="orderInfoPreview.strength"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'strength', orderInfoPreview.strength)" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('strength') && props.formButtonClicked"
              class="input-error-notification">
              Please enter strength.
            </span>
          </div>
          <div class="input-elements-group route">
            <label for="route">Administration*</label>
            <input
              id="route"
              v-model="orderInfoPreview.routeAdministration"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'routeAdministration', orderInfoPreview.routeAdministration)" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('routeAdministration') && props.formButtonClicked"
              class="input-error-notification">
              Please enter route of administration.
            </span>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group frequency">
            <label for="frequency">Dosing Schedule*</label>
            <input
              id="frequency"
              v-model="orderInfoPreview.frequency"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'frequency', orderInfoPreview.frequency)" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('frequency') && props.formButtonClicked"
              class="input-error-notification">
              Please enter frequency.
            </span>
          </div>
          <div class="input-elements-group therapy-length">
            <label for="therapy-length">Expected Length of Therapy*</label>
            <input
              id="therapy-length"
              v-model="orderInfoPreview.expectedLengthOfTherapy"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="
                emit('updateOrderInfoField', 'expectedLengthOfTherapy', orderInfoPreview.expectedLengthOfTherapy)
              " />
            <span
              v-if="props.isEditable && !fieldNotEmpty('expectedLengthOfTherapy') && props.formButtonClicked"
              class="input-error-notification">
              Please enter length of therapy.
            </span>
          </div>
          <div class="input-elements-group quantity">
            <label for="quantity">Quantity per 30 Days*</label>
            <input
              id="quantity"
              v-model="orderInfoPreview.quantityPer30Days"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'quantityPer30Days', orderInfoPreview.quantityPer30Days)" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('quantityPer30Days') && props.formButtonClicked"
              class="input-error-notification">
              Please enter quantity.
            </span>
          </div>
        </div>

        <div class="fields-in-row">
          <div class="input-elements-group date-started">
            <label for="date-started">Date Started*</label>
            <input
              id="date-started"
              v-model="orderInfoPreview.startDate"
              type="date"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'startDate', orderInfoPreview.startDate)" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('startDate') && props.formButtonClicked"
              class="input-error-notification">
              Please enter date started.
            </span>
          </div>
          <div class="input-elements-group stage-of-therapy">
            <label for="stage-of-therapy">Stage of Therapy <small>(Initial/Continuation)</small>*</label>
            <select
              v-if="props.isEditable && !orderInfoPreview.stageOfTherapy"
              id="stage-of-therapy"
              v-model="orderInfoPreview.stageOfTherapy"
              class="custom-select-arrow">
              <option disabled value="null">Select Stage of Therapy</option>
              <option v-for="stage in stagesOfTherapy" :key="stage">
                {{ stage }}
              </option>
            </select>
            <input
              v-if="!props.isEditable || orderInfoPreview.stageOfTherapy"
              id="stage-of-therapy"
              v-model="orderInfoPreview.stageOfTherapy"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updateOrderInfoField', 'stageOfTherapy', orderInfoPreview.stageOfTherapy)" />
            <span
              v-if="props.isEditable && !fieldNotEmpty('stageOfTherapy') && props.formButtonClicked"
              class="input-error-notification">
              Please enter stage of therapy.
            </span>
          </div>
        </div>

        <div v-if="orderInfoPreview.patientInfo" class="fields-in-row">
          <div class="input-elements-group height">
            <label for="height">Height (inches)*</label>
            <input
              id="height"
              v-model="orderInfoPreview.patientInfo.height"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'height', orderInfoPreview.patientInfo.height)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('height') && props.formButtonClicked"
              class="input-error-notification">
              Please enter height.
            </span>
          </div>
          <div class="input-elements-group weight">
            <label for="weight">Weight (lb)*</label>
            <input
              id="weight"
              v-model="orderInfoPreview.patientInfo.weight"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'weight', orderInfoPreview.patientInfo.weight)" />
            <span
              v-if="props.isEditable && !patientFieldNotEmpty('weight') && props.formButtonClicked"
              class="input-error-notification">
              Please enter weight.
            </span>
          </div>
          <div class="input-elements-group drug-allergies">
            <label for="drug-allergies">Drug Allergies</label>
            <input
              id="drug-allergies"
              v-model="orderInfoPreview.patientInfo.drugAllergies"
              type="text"
              placeholder=""
              :readonly="!props.isEditable"
              @change="emit('updatePatientInfo', 'drugAllergies', orderInfoPreview.patientInfo.drugAllergies)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { usaStates } from "@/utils/usaStates";
import { FORM_VALIDATION_PATTERNS } from "@/utils/constants";

const providerTitleOptions = ["MD", "DO", "NP", "PA", "PharmD"];
const stagesOfTherapy = ["New Therapy (initiation)", "Renewal (continuation)"];
const benefitTypes = ["Medical benefits", "Pharmacy benefits"];
const providerSelection = ref(null);
const patientSelection = ref(null);

const states = ref([]);
onMounted(() => {
  for (let stateData of Object.values(usaStates)) {
    states.value.push(stateData.name);
  }
});

const emit = defineEmits([
  "updatePatientInfo",
  "updateProviderInfo",
  "updateOrderInfoField",
  "setPatientInfoFromAddressBook",
  "setProviderInfoFromAddressBook",
]);

const props = defineProps({
  orderInfo: {
    type: Object,
    required: true,
  },
  isEditable: {
    type: Boolean,
    default() {
      return false;
    },
  },
  formButtonClicked: {
    type: Boolean,
    default() {
      return false;
    },
  },
  patientAddressBook: {
    type: Array,
    default() {
      return [];
    },
  },
  providerAddressBook: {
    type: Array,
    default() {
      return [];
    },
  },
});

const orderInfoPreview = ref(props.orderInfo);

// Validations
function fieldNotEmpty(field) {
  const value = orderInfoPreview.value[field];
  return value !== null && value.trim() !== "";
}

function patientFieldNotEmpty(field) {
  const value = orderInfoPreview.value.patientInfo[field];
  return value !== null && value.trim() !== "";
}

function providerFieldNotEmpty(field) {
  const value = orderInfoPreview.value.providerInfo[field];
  return value !== null && value.trim() !== "";
}

const isBinValid = computed(() => {
  const value = orderInfoPreview.value.bin;
  return value !== null && value.trim() !== "" && FORM_VALIDATION_PATTERNS.BIN_NUMBER_PATTERN.test(value);
});

const isPCNValid = computed(() => {
  const value = orderInfoPreview.value.processorControlNumber;
  return value !== null && value.trim() !== "";
});

watch(providerSelection, (newVal) => {
  if (newVal) {
    const selectedProvider = props.providerAddressBook.find(
      (provider) => `${provider.providerFirstName} ${provider.providerLastName}, ${provider.npiNumber}` === newVal
    );
    if (selectedProvider.id) {
      emit("setProviderInfoFromAddressBook", selectedProvider, selectedProvider.id);
    }
  }
});

watch(patientSelection, (newVal) => {
  if (newVal) {
    const selectedPatient = props.patientAddressBook.find(
      (patient) => `${patient.firstName} ${patient.lastName} (${patient.dob}), ${patient.insuranceId}` === newVal
    );
    if (selectedPatient.id) {
      const copyOfSelectedPatient = { ...selectedPatient };
      delete copyOfSelectedPatient.id;
      const keepPatientId = orderInfoPreview.value.patientInfo.id;
      emit("setPatientInfoFromAddressBook", copyOfSelectedPatient, keepPatientId);
    }
  }
});
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
