<!-- eslint-disable prettier/prettier -->
<template>
  <PriorHeader />
  <div class="submission-status-wrapper">
    <div class="shadow-ellipse shadow-ellipse-right"></div>
    <div class="shadow-ellipse"></div>
    <h1>All Submissions</h1>
    <div v-if="!preloader" class="search-container">
      <form @submit.prevent="runSearch">
        <div>
          <label for="search-term">
            Search by Keywords in Submission <br />
            <small>(eg Keycode, Medication, Patient Name, Payor, Provider)</small>
          </label>
          <input id="search-term" v-model="searchFormData.searchTerm" type="text" placeholder="" />
        </div>
        <div class="half-width">
          <div>
            <label for="status">Filter by Status</label>
            <select id="status" v-model="searchFormData.status" class="custom-select-arrow">
              <option value="">All Submissions</option>
              <option value="INCOMPLETE">Incomplete</option>
              <option value="SENT_TO_PLAN">Sent to Plan</option>
              <option value="APPROVED">Approved</option>
              <option value="DENIED">Denied</option>
            </select>
          </div>
          <div>
            <label for="ordering">Filter by Date</label>
            <select id="ordering" v-model="searchFormData.orderBy" class="custom-select-arrow">
              <option value="">Most Recent</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>
        <div style="grid-column: 2" class="buttons-container">
          <button type="submit">Search</button>
          <button @click="clearSearch">Clear Search</button>
        </div>
      </form>
    </div>
    <div v-if="preloader">
      <GreenCirclePreloader />
    </div>
    <div v-if="!preloader && availableOrders.length === 0">
      <h2>No Submissions Available</h2>
    </div>
    <div v-if="!preloader && availableOrders.length > 0">
      <div v-for="order in availableOrders" :key="order.orderKey" @click="selectSubmission(order)">
        <div class="card">
          <div class="card-header">
            <span style="grid-column: 1"><strong>Patient Name:</strong> {{ getPatientName(order) }} </span>
            <span style="grid-column: 2"><strong>Order Status:</strong> {{ order.status }}</span>
          </div>
          <div class="card-content">
            <div class="card-row request-text">
              <span><strong>Date Created:</strong> {{ convertDateFormat(order.createdOn) }}</span>
              <span><strong>Insurance:</strong> {{ titleCaseString(order.insuranceProvider) }}</span>
              <span><strong>Key:</strong> {{ order.orderKey }}</span>
            </div>
            <div class="card-row request-text">
              <span><strong>Date Modified:</strong> {{ convertDateFormat(order.modifiedOn) }}</span>
              <span><strong>Plan Type:</strong> {{ order.insurancePlanType }}</span>
              <span><strong>Medication:</strong> {{ titleCaseString(order.medication) }}</span>
            </div>
            <div class="card-row request-text">
              <span v-if="expiresSoon(order)"><strong class="red-text">Expires soon!</strong></span>
              <hr v-else class="light-hr" />
              <span>
                <strong>Prior Auth Number:</strong>
                {{ order.submission ? order.submission.priorAuthNumber : "" }}
              </span>
              <span>
                <strong>Expires on:</strong>
                {{ !order.submission ? "" : order.submission.priorAuthExpiration ? convertDateFormat(order.submission.priorAuthExpiration) : "" }}
              </span>
            </div>
            <div class="card-row request-text">
              <span>
                <strong>Submission Status:</strong>
                {{ order.submission ? order.submission.status : "" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!preloader" class="pagination">
    <button
      v-for="index in pageCount"
      :key="index"
      class="page-button"
      :class="{ active: index === currentPage }"
      @click="getAvailableOrders(index)">
      {{ index }}
    </button>
  </div>
  <PriorFooter />
</template>

<script setup>
import { ref, onMounted } from "vue";
import PriorHeader from "@/components/PriorHeader";
import PriorFooter from "@/components/PriorFooter";
import { convertDateFormat, titleCaseString } from "@/utils";
import { orderInfoService } from "@/services/orderInfoService";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import { useSelectedSubmissionStore } from "@/stores/selectedSubmissionStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

const router = useRouter();

const { selectedSubmission } = storeToRefs(useSelectedSubmissionStore());

const availableOrders = ref([]);
const preloader = ref(false);
const pageCount = ref(1);
const currentPage = ref(1);

const searchFormData = ref({
  searchTerm: "",
  status: "",
  orderBy: "",
});

onMounted(() => {
  getAvailableOrders(currentPage.value);
});

const searchRunning = ref(false);

function clearSearch() {
  if (searchRunning.value) {
    searchRunning.value = false;
    searchFormData.value = {
      searchTerm: "",
      status: "",
      orderBy: "",
    };
    getAvailableOrders(1);
  }
}

function runSearch() {
  searchRunning.value = true;
  getAvailableOrders(1);
}

async function getAvailableOrders(index) {
  currentPage.value = index;
  preloader.value = true;
  const response = searchRunning.value
    ? await orderInfoService.searchOrders(searchFormData.value, index)
    : await orderInfoService.getListOfOrders(index);
  availableOrders.value = [...response.results];
  pageCount.value = response.count ? Math.ceil(response.count / 10) : 1;
  await new Promise((resolve) => setTimeout(resolve, 0));
  window.scrollTo(0, 0);
  preloader.value = false;
}

function getPatientName(order) {
  return order.patientInfo && order.patientInfo.firstName && order.patientInfo.lastName
    ? `${order.patientInfo.firstName} ${order.patientInfo.lastName}`
    : "Patient Information Form Not Completed";
}

function selectSubmission(order) {
  selectedSubmission.value = order;
  if (order.pdfSubmission) {
    router.push({ name: "pdf-submission-details", params: { id: selectedSubmission.value.orderKey } });
  } else {
    router.push({ name: "submission-details", params: { id: selectedSubmission.value.orderKey } });
  }
}

const expiresSoon = (order) => {
  if (!order.priorAuthExpiration) return false;
  const expirationDate = new Date(order.priorAuthExpiration);
  const today = new Date();
  const timeDifference = expirationDate.getTime() - today.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return daysDifference <= 7;
};
</script>

<style lang="scss" scoped>
@import "../styles/pages/_submission-status.scss";
</style>
