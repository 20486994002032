<template>
  <PriorHeader />
  <div class="graph-page-wrapper">
    <div class="shadow-ellipse shadow-ellipse-right"></div>
    <div class="shadow-ellipse shadow-ellipse-left"></div>
    <div v-if="showPatientForm" class="questionaire-wrapper">
      <PatientInformationForm @add-data="addDiagnosisAndAutofillingData" />
    </div>
    <div v-else-if="showStepTherapiesForm">
      <StepTherapiesForm @next-clicked="nextClicked" />
    </div>
    <div v-else-if="showPayerQuestionsForm">
      <PayerQuestionsForm @next-clicked="nextClicked" />
    </div>
    <GreenCirclePreloader v-if="preloader" />
    <div v-else-if="showSupportingDocsForm">
      <SupportingDocsFormVue @next-clicked="nextClicked" />
    </div>
  </div>
  <div v-if="showReviewPage" id="smart-engine-wrapper">
    <ReviewAndSendToPlanPage />
  </div>
  <div v-if="showDataFetchingPage">
    <SuccessPage :title="dataFetchingTitle" :message="dataFetchingMessage" />
  </div>
  <PriorFooter />
</template>

<script setup>
import { onMounted, ref, onUnmounted, computed } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import {
  clearOrderInfoStorage,
  initializePatientInfo,
  initializeProviderInfo,
  initializePrimaryDiagnosis,
  hasDuplicate,
} from "@/utils";
import { mainServices } from "@/services/mainServices";
import { orderInfoService } from "@/services/orderInfoService";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { useRequirementsStore } from "@/stores/requirementsStore";

const { orderInfo } = storeToRefs(useOrderInfoStore());
const { requirementsData } = storeToRefs(useRequirementsStore());

import PriorFooter from "@/components/PriorFooter.vue";
import PriorHeader from "@/components/PriorHeader.vue";
import GreenCirclePreloader from "@/components/GreenCirclePreloader.vue";
import ReviewAndSendToPlanPage from "@/pages/ReviewAndSendToPlanPage.vue";
import PatientInformationForm from "@/pages/PatientInformationForm.vue";
import StepTherapiesForm from "@/pages/StepTherapiesForm.vue";
import SupportingDocsFormVue from "@/pages/SupportingDocsForm.vue";
import PayerQuestionsForm from "@/pages/PayerQuestionsForm.vue";
import SuccessPage from "@/pages/SuccessPage.vue";

const route = useRoute();

const preloader = ref(false);
const showPatientForm = ref(false);
const showStepTherapiesForm = ref(false);
const showSupportingDocsForm = ref(false);
const showPayerQuestionsForm = ref(false);
const showDataFetchingPage = ref(false);
const showReviewPage = ref(false);
const currentFormView = ref("Patient and Provider Information");
const selectedDiagnoses = ref([]);
const dataFetchingTitle = "Fetching clinical questions and autofilling";
const dataFetchingMessage =
  "A notification will be sent to your email when ready for review. You can also check the status in all submissions.";

onMounted(() => {
  if (route.params.id && route.params.id.length > 8) {
    showPatientForm.value = true;
    getPriorAuthRequirements(route.params.id);
  } else if (route.params.id && route.params.id.length <= 8) {
    clearOrderInfoStorage();
    openFormStep(route.params.id);
  } else {
    showPatientForm.value = true;
    preloader.value = true;
    preloader.value = false;
  }
});

onUnmounted(() => {
  clearOrderInfoStorage();
});

async function savePartialOrderInfo() {
  await orderInfoService.saveOrder(orderInfo.value.orderKey, orderInfo.value);
}

const suggestedGuidelines = computed(() => {
  if (selectedDiagnoses.value) {
    const suggestedGuidelines = [];
    selectedDiagnoses.value.forEach((diagnosis) => {
      if (diagnosis.clinicalGuidelines && diagnosis.clinicalGuidelines.length) {
        suggestedGuidelines.push(...diagnosis.clinicalGuidelines);
      }
    });
    if (requirementsData.value.medicationRequirements && requirementsData.value.medicationRequirements.length) {
      requirementsData.value.medicationRequirements.forEach((medicationRequirement) => {
        suggestedGuidelines.push(...medicationRequirement.clinicalGuidelines);
      });
    }
    return Array.from(new Set(suggestedGuidelines.map((guideline) => guideline.url))).map((url) =>
      suggestedGuidelines.find((guideline) => guideline.url === url)
    );
  } else if (orderInfo.value.guidelines) {
    return orderInfo.value.guidelines;
  } else {
    return [];
  }
});

const medicalNecessityTemplate = computed(() => {
  if (selectedDiagnoses.value) {
    const medicalNecessityTemplate = selectedDiagnoses.value.filter(
      (diagnosis) => diagnosis.diagnosisType === "Primary"
    )[0]
      ? diagnosis.medicalNecessityTemplate
      : "";
    return medicalNecessityTemplate;
  } else if (orderInfo.value.medicalNecessityTemplate) {
    return orderInfo.value.medicalNecessityTemplate;
  } else {
    return "";
  }
});

async function addDiagnosisAndAutofillingData(diagnosis, autoFillingOption) {
  selectedDiagnoses.value.push(...diagnosis);
  diagnosis.forEach((diagnosis) => {
    if (diagnosis.stepTherapyRequirements && diagnosis.stepTherapyRequirements.length) {
      diagnosis.stepTherapyRequirements.forEach((step) => {
        orderInfo.value.stepTherapyRequired.value.push(step);
      });
    }
    if (diagnosis.labResults && diagnosis.labResults.length) {
      diagnosis.labResults.forEach((labResult) => {
        if (!hasDuplicate(orderInfo.value.labResults, labResult)) {
          orderInfo.value.labResults.push({ label: labResult.label, links: [] });
        }
      });
    }
    if (diagnosis.patientChartNotes && diagnosis.patientChartNotes.length) {
      diagnosis.patientChartNotes.forEach((note) => {
        if (!hasDuplicate(orderInfo.value.patientChart, note)) {
          orderInfo.value.patientChart.push({ label: note.label, links: [] });
        }
      });
    }
  });
  if (requirementsData.value.stepTherapyRequirements && requirementsData.value.stepTherapyRequirements.length) {
    requirementsData.value.stepTherapyRequirements.forEach((step) => {
      orderInfo.value.stepTherapyRequired.push(step);
      if (step.labResults && step.labResults.length) {
        step.labResults.forEach((labResult) => {
          if (!hasDuplicate(orderInfo.value.labResults, labResult)) {
            orderInfo.value.labResults.push({ label: labResult.label, links: [] });
          }
        });
      }
      if (step.patientChartNotes && step.patientChartNotes.length) {
        step.patientChartNotes.forEach((note) => {
          if (!hasDuplicate(orderInfo.value.patientChart, note)) {
            orderInfo.value.patientChart.push({ label: note.label, links: [] });
          }
        });
      }
    });
  }
  if (requirementsData.value.medicationRequirements && requirementsData.value.medicationRequirements.length) {
    requirementsData.value.medicationRequirements.forEach((medication) => {
      if (medication.labResults && medication.labResults.length) {
        medication.labResults.forEach((labResult) => {
          if (!hasDuplicate(orderInfo.value.labResults, labResult)) {
            orderInfo.value.labResults.push({ label: labResult.label, links: [] });
          }
        });
      }
      if (medication.patientChartNotes && medication.patientChartNotes.length) {
        medication.patientChartNotes.forEach((note) => {
          if (!hasDuplicate(orderInfo.value.patientChart, note)) {
            orderInfo.value.patientChart.push({ label: note.label, links: [] });
          }
        });
      }
    });
  }
  orderInfo.value.guidelines = suggestedGuidelines.value;
  orderInfo.value.medicalNecessityTemplate = medicalNecessityTemplate.value;
  savePartialOrderInfo();
  if (autoFillingOption === "manualEntry") {
    nextClicked();
  } else {
    showPatientForm.value = false;
    showDataFetchingPage.value = true;
  }
}

async function getPriorAuthRequirements(id) {
  preloader.value = true;
  const fetchedRequirements = await mainServices.getRequirementsData(id);
  if (fetchedRequirements.payerQuestions.length) {
    fetchedRequirements.payerQuestions.forEach((requirement) => {
      requirementsData.value.payerQuestions.push(requirement);
    });
  }
  if (fetchedRequirements.stepTherapyRequirements.length) {
    fetchedRequirements.stepTherapyRequirements.forEach((requirement) => {
      requirementsData.value.stepTherapyRequirements.push(requirement);
    });
  }
  preloader.value = false;
}

function hideAllFormSteps() {
  showPatientForm.value = false;
  showStepTherapiesForm.value = false;
  showPayerQuestionsForm.value = false;
  showSupportingDocsForm.value = false;
  showReviewPage.value = false;
  showDataFetchingPage.value = false;
}

async function openFormStep(orderKey) {
  preloader.value = true;
  const fetchedOrder = await orderInfoService.getOrderDetails(orderKey);
  orderInfo.value = fetchedOrder.orderInfo;
  currentFormView.value = orderInfo.value.formStep;
  if (!orderInfo.value.patientInfo) {
    initializePatientInfo();
  }
  if (!orderInfo.value.providerInfo) {
    initializeProviderInfo();
  }
  if (!orderInfo.value.primaryDiagnosis) {
    initializePrimaryDiagnosis();
  }
  hideAllFormSteps();
  if (currentFormView.value === "Patient and Provider Information") {
    showPatientForm.value = true;
  } else if (currentFormView.value === "Step Therapies Information") {
    showStepTherapiesForm.value = true;
  } else if (currentFormView.value === "Payer Questions") {
    if (orderInfo.value.payerQuestions && orderInfo.value.payerQuestions.length) {
      showPayerQuestionsForm.value = true;
    } else {
      showSupportingDocsForm.value = true;
      currentFormView.value = "Supporting Documents";
    }
  } else if (currentFormView.value === "Supporting Documents") {
    showSupportingDocsForm.value = true;
  } else if (currentFormView.value === "Review") {
    showReviewPage.value = true;
  }
  preloader.value = false;
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function nextClicked() {
  if (currentFormView.value === "Patient and Provider Information") {
    showPatientForm.value = false;
    showStepTherapiesForm.value = true;
    currentFormView.value = "Step Therapies Information";
  } else if (currentFormView.value === "Payer Questions") {
    showPayerQuestionsForm.value = false;
    showSupportingDocsForm.value = true;
    currentFormView.value = "Supporting Documents";
  } else if (currentFormView.value === "Step Therapies Information") {
    showStepTherapiesForm.value = false;
    if (orderInfo.value.payerQuestions && orderInfo.value.payerQuestions.length) {
      showPayerQuestionsForm.value = true;
      currentFormView.value = "Payer Questions";
    } else {
      showSupportingDocsForm.value = true;
      currentFormView.value = "Supporting Documents";
    }
  } else if (currentFormView.value === "Supporting Documents") {
    showSupportingDocsForm.value = false;
    showReviewPage.value = true;
    currentFormView.value = "Review";
  }
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_requirements-page";
</style>
